<template>
  <Password />
</template>
<script>
  import Password from "@/components/Auth/Password/Password";
  export default {
    components: {
      Password,
    },
  };
</script>
