<template>
  <div>
    <Card class="w-full h-full" shadow>
      <div class="pb-2 md:pb-4mx-auto">
        <div class="">
          <h4
            class="
              text-grey
              hidden
              md:block
              font-bold
              md:text-xl
              text-xl text-center
              md:text-left
            "
          >
            Create a password for your account
          </h4>

          <form @submit.prevent="completeRegistration()">
            <Input
              class="mt-6"
              placeholder="Email"
              id="email"
              type="email"
              width="w-full"
              v-model="activeUser.email"
              disabled
            />
            <Input
              id="password"
              class="mt-6"
              placeholder="Password"
              type="password"
              width="w-full"
              revealPassword
              v-model="user.password"
              errorText="Not a valid password format"
              :validation="rules.password"
            />
            <!-- <Input
              class="mt-6"
              placeholder="Confirm password"
              revealPassword
              type="password"
              width="w-full"
              v-model="user.confirmPassword"
              errorText="Passwords don't match"
              :validation="rules.confirmPassword"
            /> -->

            <Button
              :disabled="disableButton"
              text="Continue"
              width="w-full"
              class="mt-8"
              shadow
              type="submit"
              :loading="loading"
            />
          </form>
          <div class="text-grey mt-4">
            <p class="text-sm mb-1">Password should be</p>
            <div class="md:grid grid-cols-2">
              <div class="flex items-center">
                <div>
                  <img
                    src="@/assets/icons/tick.svg"
                    alt=""
                    class="place-self-center h-3 w-3 mr-3"
                    v-if="rules.passwordLength"
                  />
                  <div class="dot mr-3 h-3 w-3" v-else></div>
                </div>
                <p class="text-sm">At least 8 characters long</p>
              </div>
              <div class="flex items-center">
                <div>
                  <img
                    src="@/assets/icons/tick.svg"
                    alt=""
                    class="place-self-center h-3 w-3 mr-3"
                    v-if="rules.passwordUppercase"
                  />
                  <div class="dot mr-3 h-3 w-3" v-else></div>
                </div>
                <p class="text-sm">Minimum one uppercase letter</p>
              </div>
              <div class="flex items-center">
                <div>
                  <img
                    src="@/assets/icons/tick.svg"
                    alt=""
                    class="place-self-center h-3 w-3 mr-3"
                    v-if="rules.passwordLowercase"
                  />
                  <div class="dot mr-3 h-3 w-3" v-else></div>
                </div>
                <p class="text-sm">At least one lowercase letter</p>
              </div>
              <div class="flex items-center">
                <div>
                  <img
                    src="@/assets/icons/tick.svg"
                    alt=""
                    class="place-self-center h-3 w-3 mr-3"
                    v-if="rules.passwordNumber"
                  />
                  <div class="dot mr-3 h-3 w-3" v-else></div>
                </div>
                <p class="text-sm">Minimum one number</p>
              </div>
              <div class="flex items-center">
                <div>
                  <img
                    src="@/assets/icons/tick.svg"
                    alt=""
                    class="place-self-center h-3 w-3 mr-3"
                    v-if="rules.passwordSpecialCharacter"
                  />
                  <div class="dot mr-3 h-3 w-3" v-else></div>
                </div>
                <p class="text-sm">At least one special character</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Card>
    <largeModal :display="showProfile" size="large" hideClose>
      <div class="lg:w-3/5 mx-auto mt-12">
        <p
          class="
            text-center text-xl
            md:text-2xl
            font-bold
            primary-text
            profile-title
          "
        >
          You’re almost good to go; one last thing
        </p>
        <p class="text-center text-grey mt-2 md:text-lg">
          What would you like us to call you?
        </p>
        <form @submit.prevent="setNickName()">
          <Input
            width="w-full"
            class="mt-8"
            placeholder="Nickname"
            v-model="profileName"
            :validation="profileName.length > 0"
            errorText="Username field cannot be empty"
          />
          <Button
            :disabled="disableProfileButton"
            text="Continue to your account"
            width="w-full"
            class="mt-12"
            :loading="profileLoading"
            type="submit"
          />
        </form>
      </div>
    </largeModal>
  </div>
</template>
<script>
import { mapState, mapActions } from "vuex";
import { Input } from "@/UI/Input";
import { Button } from "@/UI/Button";
import { LargeModal } from "@/UI/Modals";
export default {
  components: {
    Input,
    Button,
    LargeModal,
  },
  data: () => ({
    showProfile: false,
    profileLoading: false,
    loading: false,
    profileName: "",
    user: {
      // confirmPassword: "",
      password: "",
    },
  }),
  mounted() {
    this.getRegistrationDetails();
  },
  computed: {
    ...mapState({
      activeUser: (state) => state?.auth?.user,
      registrationOtp: (state) => state.auth.registrationOtp,
      registrationId: (state) => state.auth.registrationId,
    }),

    rules() {
      return {
        password:
          /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,}$/.test(
            this.user.password
          ),

        // confirmPassword: this.user.password === this.user.confirmPassword,
        passwordLength: this.user.password.length >= 8,
        passwordUppercase: /^(?=.*[A-Z])/.test(this.user.password),
        passwordLowercase: /^(?=.*?[a-z])/.test(this.user.password),
        passwordNumber: /^(?=.*?[0-9])/.test(this.user.password),
        passwordSpecialCharacter:
          /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(this.user.password),
      };
    },
    disableButton() {
      return Object.values(this.rules).includes(false);
    },
    disableProfileButton() {
      return this.profileName.length < 1;
    },
  },
  methods: {
    ...mapActions("auth", [
      "submitPassword",
      "login",
      "addNickname",
      "completeAnonymousRegistration",
    ]),
    ...mapActions("dashboard", ["handleShowReminder"]),
    getRegistrationDetails() {
      if (this.$route.query.id && this.$route.query.email) {
        this.completeAnonymousRegistration({
          id: this.$route.query.id,
          email: this.$route.query.email,
        });
      }
    },
    completeRegistration() {
      this.loading = true;
      this.submitPassword({
        registrationId: this.registrationId,
        otp: this.registrationOtp,
        password: this.user.password,
      })
        .then(() => {
          this.loading = false;
          this.showProfile = true;
        })
        .then(() => {
          this.loading = false;
          this.showProfile = true;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    setNickName() {
      this.profileLoading = true;
      this.addNickname({ nickName: this.profileName })
        .then(() => {
          // login the user
          this.login({
            username: this.activeUser.email,
            secret: this.user.password,
            loginAs: "CUSTOMER",
          })
            .then(() => {
              this.handleShowReminder(true);

              //   hide the nickname dialog
              this.profileLoading = false;
              this.showProfile = false;
            })
            .catch(() => {
              this.profileLoading = false;
            });
        })
        .catch(() => {
          this.profileLoading = false;
        });
    },
  },
};
</script>
<style scoped>
.dot {
  border-radius: 50%;
  border: 1px solid #bdbeda;
}
.profile-title {
  /* font-size: 2.875rem; */
  line-height: 120%;
}
</style>
