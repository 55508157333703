var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("Card", { staticClass: "w-full h-full", attrs: { shadow: "" } }, [
        _c("div", { staticClass: "pb-2 md:pb-4mx-auto" }, [
          _c("div", {}, [
            _c(
              "h4",
              {
                staticClass:
                  "\n            text-grey\n            hidden\n            md:block\n            font-bold\n            md:text-xl\n            text-xl text-center\n            md:text-left\n          ",
              },
              [_vm._v(" Create a password for your account ")]
            ),
            _c(
              "form",
              {
                on: {
                  submit: function ($event) {
                    $event.preventDefault()
                    return _vm.completeRegistration()
                  },
                },
              },
              [
                _c("Input", {
                  staticClass: "mt-6",
                  attrs: {
                    placeholder: "Email",
                    id: "email",
                    type: "email",
                    width: "w-full",
                    disabled: "",
                  },
                  model: {
                    value: _vm.activeUser.email,
                    callback: function ($$v) {
                      _vm.$set(_vm.activeUser, "email", $$v)
                    },
                    expression: "activeUser.email",
                  },
                }),
                _c("Input", {
                  staticClass: "mt-6",
                  attrs: {
                    id: "password",
                    placeholder: "Password",
                    type: "password",
                    width: "w-full",
                    revealPassword: "",
                    errorText: "Not a valid password format",
                    validation: _vm.rules.password,
                  },
                  model: {
                    value: _vm.user.password,
                    callback: function ($$v) {
                      _vm.$set(_vm.user, "password", $$v)
                    },
                    expression: "user.password",
                  },
                }),
                _c("Button", {
                  staticClass: "mt-8",
                  attrs: {
                    disabled: _vm.disableButton,
                    text: "Continue",
                    width: "w-full",
                    shadow: "",
                    type: "submit",
                    loading: _vm.loading,
                  },
                }),
              ],
              1
            ),
            _c("div", { staticClass: "text-grey mt-4" }, [
              _c("p", { staticClass: "text-sm mb-1" }, [
                _vm._v("Password should be"),
              ]),
              _c("div", { staticClass: "md:grid grid-cols-2" }, [
                _c("div", { staticClass: "flex items-center" }, [
                  _c("div", [
                    _vm.rules.passwordLength
                      ? _c("img", {
                          staticClass: "place-self-center h-3 w-3 mr-3",
                          attrs: {
                            src: require("@/assets/icons/tick.svg"),
                            alt: "",
                          },
                        })
                      : _c("div", { staticClass: "dot mr-3 h-3 w-3" }),
                  ]),
                  _c("p", { staticClass: "text-sm" }, [
                    _vm._v("At least 8 characters long"),
                  ]),
                ]),
                _c("div", { staticClass: "flex items-center" }, [
                  _c("div", [
                    _vm.rules.passwordUppercase
                      ? _c("img", {
                          staticClass: "place-self-center h-3 w-3 mr-3",
                          attrs: {
                            src: require("@/assets/icons/tick.svg"),
                            alt: "",
                          },
                        })
                      : _c("div", { staticClass: "dot mr-3 h-3 w-3" }),
                  ]),
                  _c("p", { staticClass: "text-sm" }, [
                    _vm._v("Minimum one uppercase letter"),
                  ]),
                ]),
                _c("div", { staticClass: "flex items-center" }, [
                  _c("div", [
                    _vm.rules.passwordLowercase
                      ? _c("img", {
                          staticClass: "place-self-center h-3 w-3 mr-3",
                          attrs: {
                            src: require("@/assets/icons/tick.svg"),
                            alt: "",
                          },
                        })
                      : _c("div", { staticClass: "dot mr-3 h-3 w-3" }),
                  ]),
                  _c("p", { staticClass: "text-sm" }, [
                    _vm._v("At least one lowercase letter"),
                  ]),
                ]),
                _c("div", { staticClass: "flex items-center" }, [
                  _c("div", [
                    _vm.rules.passwordNumber
                      ? _c("img", {
                          staticClass: "place-self-center h-3 w-3 mr-3",
                          attrs: {
                            src: require("@/assets/icons/tick.svg"),
                            alt: "",
                          },
                        })
                      : _c("div", { staticClass: "dot mr-3 h-3 w-3" }),
                  ]),
                  _c("p", { staticClass: "text-sm" }, [
                    _vm._v("Minimum one number"),
                  ]),
                ]),
                _c("div", { staticClass: "flex items-center" }, [
                  _c("div", [
                    _vm.rules.passwordSpecialCharacter
                      ? _c("img", {
                          staticClass: "place-self-center h-3 w-3 mr-3",
                          attrs: {
                            src: require("@/assets/icons/tick.svg"),
                            alt: "",
                          },
                        })
                      : _c("div", { staticClass: "dot mr-3 h-3 w-3" }),
                  ]),
                  _c("p", { staticClass: "text-sm" }, [
                    _vm._v("At least one special character"),
                  ]),
                ]),
              ]),
            ]),
          ]),
        ]),
      ]),
      _c(
        "largeModal",
        { attrs: { display: _vm.showProfile, size: "large", hideClose: "" } },
        [
          _c("div", { staticClass: "lg:w-3/5 mx-auto mt-12" }, [
            _c(
              "p",
              {
                staticClass:
                  "\n          text-center text-xl\n          md:text-2xl\n          font-bold\n          primary-text\n          profile-title\n        ",
              },
              [_vm._v(" You’re almost good to go; one last thing ")]
            ),
            _c("p", { staticClass: "text-center text-grey mt-2 md:text-lg" }, [
              _vm._v(" What would you like us to call you? "),
            ]),
            _c(
              "form",
              {
                on: {
                  submit: function ($event) {
                    $event.preventDefault()
                    return _vm.setNickName()
                  },
                },
              },
              [
                _c("Input", {
                  staticClass: "mt-8",
                  attrs: {
                    width: "w-full",
                    placeholder: "Nickname",
                    validation: _vm.profileName.length > 0,
                    errorText: "Username field cannot be empty",
                  },
                  model: {
                    value: _vm.profileName,
                    callback: function ($$v) {
                      _vm.profileName = $$v
                    },
                    expression: "profileName",
                  },
                }),
                _c("Button", {
                  staticClass: "mt-12",
                  attrs: {
                    disabled: _vm.disableProfileButton,
                    text: "Continue to your account",
                    width: "w-full",
                    loading: _vm.profileLoading,
                    type: "submit",
                  },
                }),
              ],
              1
            ),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }